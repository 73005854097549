import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "dcCategoryForm",
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      model: _vm.item
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "分类名称",
      prop: "name",
      rules: {
        required: true,
        message: "请输入分类名称"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入英文",
      disabled: _vm.isDisabled,
      maxlength: "200"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    },
    model: {
      value: _vm.item.name,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "排序",
      prop: "sort",
      rules: {
        required: true,
        message: "请输入排序"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入排序",
      disabled: _vm.isDisabled,
      maxlength: "10"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    },
    model: {
      value: _vm.item.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "sort", $$v);
      },
      expression: "item.sort"
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };