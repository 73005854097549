import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { DcToolApi } from "@/api";
export default {
  name: "dcCategoryForm",
  components: {},
  props: {
    editId: [String, Number],
    categoryTitle: String
  },
  data: function data() {
    return {
      isEdit: "",
      preVisible: false,
      fetching: false,
      disabledLoading: false,
      item: {}
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return this.categoryTitle == "编辑分类";
    },
    imgProportion: function imgProportion() {
      return [536, 538];
    }
  },
  created: function created() {
    if (this.editId) {
      this.getCategoryDetail();
    }
  },
  methods: {
    getCategoryDetail: function getCategoryDetail() {
      var _this = this;
      DcToolApi.categoryInfo({
        id: this.editId
      }).then(function (res) {
        _this.item = res;
      });
    },
    cancle: function cancle() {
      this.$emit("handleAddCancle");
    },
    submit: function submit() {
      var _this2 = this;
      if (this.disabledLoading) return;
      this.$refs.dcCategoryForm.validate(function (valid) {
        if (valid) {
          _this2.disabledLoading = true;
          var params = _objectSpread({}, _this2.item);
          var method = "categoryAdd";
          if (_this2.editId) {
            method = "categoryEdit";
            params.id = _this2.editId;
          }
          //   console.log()
          DcToolApi[method](_objectSpread({}, params)).then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("handleAddSubmit");
          }).catch(function () {
            _this2.disabledLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    revalidateField: function revalidateField(field) {
      var _this3 = this;
      setTimeout(function () {
        _this3.$refs.dcCategoryForm.validateField(field);
      }, 100);
    }
  }
};